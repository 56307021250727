import React, {useState} from 'react'
import {isPossiblePhoneNumber} from 'react-phone-number-input'

import {AccountInviteEntryPoint} from '@posh/models'
import {useCreateAccountWithTempPassword} from 'apis/Accounts/useCreateAccountWithTempPassword'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import PhoneNumberInput from 'components/form/PhoneNumberInput'

import {AccountParams} from '..'

interface AccountSearchModalSecondaryStepProps {
  searchedEmail: string
  searchedPhone: string
  secondaryTitle: string
  secondaryButtonText: string
  onAddUser: (account: AccountParams) => Promise<void>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  switchToPrimaryStep: () => void
  isLoading: boolean
  onSecondaryButtonClick?: () => void
  invitedFrom: AccountInviteEntryPoint
}

const AccountSearchModalSecondaryStep = (props: AccountSearchModalSecondaryStepProps) => {
  const {
    searchedEmail,
    searchedPhone,
    secondaryTitle,
    secondaryButtonText,
    onAddUser,
    setErrorMessage,
    switchToPrimaryStep,
    isLoading,
    onSecondaryButtonClick,
    invitedFrom,
  } = props
  const {mutateAsync: createAccountWithTempPassword, isLoading: isCreatingAccount} = useCreateAccountWithTempPassword()
  const [firstName, setFirstName] = useState<string | undefined>(undefined)
  const [lastName, setLastName] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState<string | undefined>(searchedEmail || undefined)
  const [phone, setPhone] = useState<string | undefined>(searchedPhone || undefined)

  const resetModal = () => {
    setFirstName(undefined)
    setLastName(undefined)
    setEmail(undefined)
    setPhone(undefined)
    switchToPrimaryStep()
  }

  const handleCreateAccountProcess = async () => {
    onSecondaryButtonClick?.()
    try {
      setErrorMessage('')
      const accountFormValidations = [
        {failureCondition: !firstName, message: 'First name is required'},
        {failureCondition: firstName && firstName.length < 2, message: 'First name must be at least 2 characters'},
        {failureCondition: !lastName, message: 'Last name is required'},
        {failureCondition: lastName && lastName.length < 2, message: 'Last name must be at least 2 characters'},
        {failureCondition: !email && !phone, message: 'An email or a phone number is required'},
        {failureCondition: phone && !isPossiblePhoneNumber(phone), message: 'Phone must be valid'},
        {failureCondition: email && !email.includes('@'), message: 'Email must be valid'},
      ]

      accountFormValidations.forEach(validation => {
        if (validation.failureCondition) {
          throw new Error(validation.message)
        }
      })
      const accountId = await createAccountWithTempPassword({
        firstName: firstName!,
        lastName: lastName!,
        email,
        phone,
        invitedFrom,
      })
      await onAddUser({accountId, email, phone})
      resetModal()
    } catch (error) {
      if (error.response) setErrorMessage(error.response.data.error)
      else setErrorMessage(error.message)
      return
    }
  }

  return (
    <div className='AccountSearchModal'>
      <h3>{secondaryTitle}</h3>
      <div className='AccountSearchModal-inputWrapper'>
        <Input
          className='fullWidth'
          type='text'
          onChange={e => setFirstName(e.target.value)}
          placeholder='First Name *'
          value={firstName}
          required
        />
        <Input
          className='fullWidth'
          type='text'
          onChange={e => setLastName(e.target.value)}
          placeholder='Last Name *'
          value={lastName}
          required
        />
      </div>
      <Input
        className='fullWidth'
        type='text'
        onChange={e => setEmail(e.target.value)}
        placeholder='Email'
        value={email}
      />
      <PhoneNumberInput
        placeholder='Phone Number'
        value={phone ?? ''}
        defaultCountry='US'
        onChange={e => setPhone(e ?? '')}
      />
      <Button disabled={isLoading || isCreatingAccount} className='fullWidth' onClick={handleCreateAccountProcess}>
        {secondaryButtonText}
      </Button>
    </div>
  )
}

export default AccountSearchModalSecondaryStep
