import React, {PropsWithChildren} from 'react'

import {StatsigProvider as StatsigProviderBase} from '@statsig/react-bindings'
import useSessionContext from 'domains/Auth/SessionContext'
import {environment} from 'helpers/environment'

export const StatsigProvider = (props: PropsWithChildren) => {
  const {children} = props

  // The userId from the session context is actually the accountId
  const {userId: accountId} = useSessionContext()

  const locale = Intl.DateTimeFormat().resolvedOptions().locale

  const STATSIG_API_KEY = process.env.STATSIG_API_KEY

  if (!STATSIG_API_KEY) {
    return children
  }

  return (
    <StatsigProviderBase
      sdkKey={STATSIG_API_KEY}
      user={{
        // The userID used by Statsig needs to be the accountId
        userID: accountId ?? undefined,
        locale,
      }}
      options={{
        environment: {tier: environment},
      }}>
      {children}
    </StatsigProviderBase>
  )
}
