import {GroupPublic} from '@posh/models'
import {captureException} from '@sentry/react'
import {FetchEventResponsePublic} from 'apis/Events/useFetchEvent'
import {facebookPixelTriggerTrack} from 'apis/FbPixelHelpers'
import {TicketCount, TicketsCountState} from 'domains/Cart/CartContextProvider'

import {pushToDataLayer} from '../dataLayerUtils'

export const trackCreateCart = (
  ticketsCount: TicketsCountState,
  event: FetchEventResponsePublic['event'],
  group: GroupPublic,
) => {
  try {
    const totalNumOfTickets: TicketCount[] = []
    const {totalTix, totalPrice} = Object.values(ticketsCount).reduce(
      (acc, ticket) => {
        if (ticket.quantity > 0) {
          totalNumOfTickets.push(ticket)
          acc.totalTix += ticket.quantity
          acc.totalPrice += ticket.data.price * ticket.quantity
        }
        return acc
      },
      {totalTix: 0, totalPrice: 0},
    )

    if (event.fbPixelId) {
      const data = {
        content_category: `Added items to cart for Posh event: ${event.name}`,
        content_name: `Added ${totalTix} tickets to cart for ${event.name}`,
        currency: group.currency,
        value: totalPrice,
      }

      facebookPixelTriggerTrack('AddToCart', data, event.fbPixelId)
    }

    const cartEventData = {
      event: 'cartCreated', // Mandatory event name
      eventId: event.id,
      eventName: event.name,
      currency: group.currency ?? 'USD',
      subtotal: totalPrice,
      ticketCount: totalNumOfTickets,
    }

    // Push the event data to the dataLayer event
    pushToDataLayer(cartEventData)
  } catch (error) {
    captureException(error)
  }
}
