import {useDecision} from '@optimizely/react-sdk'
import {EOptimizelyTests} from '@posh/types'
import moment from 'moment'

import {getRandomEventNameAndDescription, getRandomFlyer, getStartAndEndDate} from './createEventDefaultAttributes'

const BASE_DEFAULT_ATTRIBUTES = {
  timezone: moment.tz.guess(),
}

export const useDeterminePendingEventDefaultAttributes = () => {
  const [decision, clientReady] = useDecision(EOptimizelyTests.CREATE_EVENT_DEFAULT_ATTRIBUTES)
  if (!clientReady) {
    return {
      defaultAttributes: BASE_DEFAULT_ATTRIBUTES,
    }
  }
  switch (decision.variationKey) {
    case 'no_default_attributes':
      return {
        defaultAttributes: BASE_DEFAULT_ATTRIBUTES,
      }
    case 'preselected_flyer':
      return {
        defaultAttributes: {
          ...BASE_DEFAULT_ATTRIBUTES,
          flyer: getRandomFlyer(),
          name: undefined,
          descriptionOrShortDescription: undefined,
          startUtc: undefined,
          endUtc: undefined,
        },
      }
    case 'predefined_attributes_and_flyer':
      const {name, description} = getRandomEventNameAndDescription()
      const {startDate, endDate} = getStartAndEndDate()
      return {
        defaultAttributes: {
          ...BASE_DEFAULT_ATTRIBUTES,
          flyer: getRandomFlyer(),
          name,
          descriptionOrShortDescription: description,
          startUtc: startDate,
          endUtc: endDate,
          timezone: moment.tz.guess(),
        },
      }
    default:
      return {
        defaultAttributes: BASE_DEFAULT_ATTRIBUTES,
      }
  }
}
