import React, {useEffect, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'

import {Checkbox} from 'components/form/checkbox/Checkbox'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {TicketSection} from 'components/PageComponents/EventVisuals/Page/Section/ticketSection'
import {CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {useTicketTransferSettings} from 'hooks/useTicketTransferSettings'

import {COLOR_PROPS} from './TicketEditorPanel'

interface TicketPrivacySettingsProps {
  form: UseFormReturn<CreateEventTicket>
}

export const TicketPrivacySettings = (props: TicketPrivacySettingsProps) => {
  const {form} = props
  const {control, watch, setValue} = form

  const approvalRequired = watch('approvalRequired') ?? false
  const isTransferable = watch('isTransferable') ?? true
  const {
    toggleIsTransferable,
    canToggleTransfer,
    shouldRenderTicketTransferSwitch,
    TICKET_TRANSFER_SETTING_LABEL,
    ticketTransferLabelSubtitle,
  } = useTicketTransferSettings({
    ticketHasApprovalRequired: approvalRequired,
    onToggleTransfer: val => {
      setValue('isTransferable', val)
    },
  })
  const password = watch('password')

  const [shouldShowPasswordInput, setShouldShowPasswordInput] = useState(!!password)

  const togglePasswordInput = () => {
    setShouldShowPasswordInput(!shouldShowPasswordInput)
  }

  useEffect(() => {
    if (!shouldShowPasswordInput) {
      form.setValue('password', undefined)
    }
  }, [shouldShowPasswordInput])

  return (
    <TicketSection title='Ticket Privacy Settings' expandable>
      <Checkbox
        checked={approvalRequired}
        onChecked={() => setValue('approvalRequired', !approvalRequired)}
        color={COLOR_PROPS.accentColor}
        hideBackground
        label='Require Approval'
        subtitle='Customers must be approved by you before purchasing this ticket'
      />
      {shouldRenderTicketTransferSwitch && (
        <Checkbox
          checked={isTransferable}
          disabled={!canToggleTransfer}
          onChecked={() => toggleIsTransferable(!isTransferable)}
          color={COLOR_PROPS.accentColor}
          hideBackground
          label={TICKET_TRANSFER_SETTING_LABEL}
          subtitle={ticketTransferLabelSubtitle}
        />
      )}
      <Checkbox
        checked={shouldShowPasswordInput}
        onChecked={togglePasswordInput}
        color={COLOR_PROPS.accentColor}
        hideBackground
        label='Password Protected'
        subtitle='Customers must enter a password on the event page to purchase this ticket'>
        {shouldShowPasswordInput && (
          <EventVisualsTextInput.Text.Controlled
            control={control}
            name='password'
            placeholder='Password'
            {...COLOR_PROPS}
          />
        )}
      </Checkbox>
    </TicketSection>
  )
}
