import {useEffect} from 'react'

import {useFeatureGate} from './useFeatureGate'

interface TicketTransferChecksParams {
  ticketHasApprovalRequired: boolean
  onToggleTransfer: (val: boolean) => void
}

export const useTicketTransferSettings = (params: TicketTransferChecksParams) => {
  const shouldRenderTicketTransferSwitch = useFeatureGate('ticket_transfer_settings')
  const {ticketHasApprovalRequired, onToggleTransfer} = params
  const canToggleTransfer = !ticketHasApprovalRequired

  const ticketTransferLabelSubtitle = canToggleTransfer
    ? 'Customers can transfer this ticket to another person after purchasing and it will no longer be valid for the original purchaser'
    : 'Ticket transfers are disabled when approval is required'
  const TICKET_TRANSFER_SETTING_LABEL = 'Allow Ticket Transfers'

  useEffect(() => {
    onToggleTransfer(canToggleTransfer)
  }, [canToggleTransfer])

  const toggleIsTransferable = (val: boolean) => {
    if (!canToggleTransfer) return
    onToggleTransfer(val)
  }

  return {
    shouldRenderTicketTransferSwitch,
    canToggleTransfer,
    ticketTransferLabelSubtitle,
    TICKET_TRANSFER_SETTING_LABEL,
    toggleIsTransferable,
  }
}
