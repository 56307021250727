import React from 'react'
import {useParams, useSearchParams} from 'react-router-dom'

import {locationSchema, TLocation} from '@posh/model-types'
import {WHERE_OPTIONS} from '@posh/types'
import {useGetEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'
import {
  UnderlinedDropdown,
  UnderlinedDropdownOption,
  UnderlinedDropdownOptionType,
} from 'components/Dropdown/UnderlinedDropdown'
import {useDimensions} from 'hooks/useDimensions'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import {PartyFoul} from 'pages/PageNotFound/PartyFoul/PartyFoul'

import {DEFAULT_LOCATION_PRESET, useSelectLocation} from '../../hooks/useSelectLocation'
import {EventPlaylist, EventPlaylistProps} from './EventPlaylist/EventPlaylist'

import styles from './EventPlaylistPage.module.scss'

const DARKEN_OPACITY = 0.5

const eventPlaylistBackgroundStyle = ({
  backgroundImage,
}: Pick<EventPlaylistProps, 'backgroundImage' | 'backgroundImagePalette'>): React.CSSProperties => {
  return {
    background: `linear-gradient(180deg, rgba(0, 0, 0, ${DARKEN_OPACITY}) 0%, rgba(0, 0, 0, ${DARKEN_OPACITY}) 100%), url(${backgroundImage}) lightgray`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}

const useEventPlaylistLocationFromSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = Object.fromEntries(searchParams.entries())
  const parsedSearchParams = locationSchema.optional().safeParse(params)

  const location: TLocation = parsedSearchParams.success
    ? parsedSearchParams.data ?? DEFAULT_LOCATION_PRESET
    : DEFAULT_LOCATION_PRESET

  const changeEventPlaylistLocationSearchParams = (location: TLocation) => {
    switch (location.type) {
      case 'preset':
        setSearchParams(location)
        break
      case 'custom':
        setSearchParams({
          type: location.type,
          location: location.location,
          // Need to convert to string for URLSearchParams.
          lat: location.lat.toString(),
          long: location.long.toString(),
        })
        break
    }
  }

  const {onSelectLocation, isLoadingNearMeLocation} = useSelectLocation({
    onLocationSelected: changeEventPlaylistLocationSearchParams,
  })

  return {location, onSelectLocation, isLoadingNearMeLocation}
}

export const EventPlaylistPage = () => {
  const slug = useParams<{slug: string}>().slug ?? ''
  const {location, onSelectLocation, isLoadingNearMeLocation} = useEventPlaylistLocationFromSearchParams()
  const {isMobile} = useDimensions()

  const whereOptions: UnderlinedDropdownOption[] = [...WHERE_OPTIONS]
    .filter(o => o !== location.location)
    .map(o => ({
      type: 'text' as UnderlinedDropdownOptionType,
      value: o,
    }))
  whereOptions.push({type: 'city-input'})

  const {data, isSuccess, isError} = useGetEventPlaylist({slug, location}, {keepPreviousData: true})

  const backgroundStyle = isSuccess ? eventPlaylistBackgroundStyle(data.playlist) : {}

  return (
    <div className={styles.EventPlaylistPage} style={backgroundStyle}>
      {!isMobile && <PoshHeader />}
      {isSuccess && (
        <EventPlaylist
          {...data.playlist}
          locationInput={
            <>
              {isLoadingNearMeLocation ? (
                <div style={{display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'flex-end'}}>
                  <h4 style={{fontSize: 30}}>finding location...</h4>
                </div>
              ) : (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                  {location.location !== 'Near Me' && <h4 style={{fontSize: 30}}>in</h4>}
                  <UnderlinedDropdown
                    selectedValue={location.location}
                    options={whereOptions}
                    onSelect={onSelectLocation}
                  />
                </div>
              )}
            </>
          }
        />
      )}
      {isError && <PartyFoul />}
    </div>
  )
}
