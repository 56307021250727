import React from 'react'

import {MAX_TICKET_QUANTITY} from '@posh/model-types'
import {ArrowWithDirection} from 'components/assets/Icons'
import {EventVisualsButton} from 'components/PageComponents/EventVisuals/Button/Button'
import {EventVisualsCheckbox} from 'components/PageComponents/EventVisuals/Form/Input/Checkbox/EventVisualsCheckbox'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {CreateEventForm, CreateEventTicket} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'

import {useTicketTransferSettings} from '../../../../../../../hooks/useTicketTransferSettings'
import {useEventVisualsContext} from '../../context/EventVisualsContext'
import {OpenTicketEditorInterface} from './TicketEditorPanel/TicketEditorPanel'

interface RSVPAdmissionSectionProps extends OpenTicketEditorInterface {
  ticket: CreateEventTicket
  setTickets: (tickets: CreateEventForm['tickets']) => void
}

const INPUT_WIDTH = 200

export const RSVPAdmissionSection = (props: RSVPAdmissionSectionProps) => {
  const {ticket, setTickets, openTicketEditor} = props
  const {
    palette: {accentColor, lightmode, buttonTextColor, textContrasting},
  } = useEventVisualsContext()
  const {approvalRequired, isTransferable, quantityAvailable} = ticket

  const toggleApprovalRequired = (val: boolean) => {
    setTickets([{...ticket, approvalRequired: val}])
  }

  const {
    shouldRenderTicketTransferSwitch,
    TICKET_TRANSFER_SETTING_LABEL,
    ticketTransferLabelSubtitle,
    toggleIsTransferable,
    canToggleTransfer,
  } = useTicketTransferSettings({
    ticketHasApprovalRequired: approvalRequired ?? false,
    onToggleTransfer: val => {
      setTickets([{...ticket, isTransferable: val}])
    },
  })

  const setMaxQty = (val: number) => {
    setTickets([{...ticket, quantityAvailable: val}])
  }

  const toggleQtyPicker = (val: boolean) => {
    if (!val) {
      setMaxQty(MAX_TICKET_QUANTITY)
    } else {
      setMaxQty(100)
    }
  }

  const rsvpIsUnlimited = quantityAvailable === MAX_TICKET_QUANTITY

  return (
    <>
      <EventVisualsSection
        headerText='RSVP Settings'
        underlined
        rightIcon={
          <EventVisualsButton onClick={() => openTicketEditor()}>
            <>
              <ArrowWithDirection color={buttonTextColor} direction='northEast' />
              Add Ticket
            </>
          </EventVisualsButton>
        }>
        <EventVisualsCheckbox
          label='Require Approval'
          subtitle='Attendees must be approved by you before they can RSVP.'
          onChecked={toggleApprovalRequired}
          checked={approvalRequired ?? false}
        />
        {shouldRenderTicketTransferSwitch && (
          <EventVisualsCheckbox
            checked={isTransferable ?? true}
            disabled={!canToggleTransfer}
            onChecked={toggleIsTransferable}
            label={TICKET_TRANSFER_SETTING_LABEL}
            subtitle={ticketTransferLabelSubtitle}
          />
        )}
        <EventVisualsCheckbox
          label='Limit event capacity'
          subtitle='Limit the number of guests who can RSVP to this event. Leave blank for unlimited RSVPs.'
          onChecked={toggleQtyPicker}
          checked={!rsvpIsUnlimited}>
          {!rsvpIsUnlimited && (
            <EventVisualsTextInput.Number
              placeholder='Spots Available'
              lightMode={lightmode}
              accentColor={accentColor}
              value={quantityAvailable}
              onChange={setMaxQty}
              style={{width: INPUT_WIDTH}}
              autoFocus
            />
          )}
        </EventVisualsCheckbox>
        <p className={`noMargin center`} style={{color: textContrasting}}>
          This is currently an RSVP event.
        </p>
      </EventVisualsSection>
    </>
  )
}
