import {buildYoutubeVideoUrl, DEFAULT_EVENT_PAGE_TITLE_FONT} from '@posh/model-types'
import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'
import moment from 'moment'

import {GetPendingEventRawOutput} from './useGetPendingEvent'

type UpdatePendingEventOptions = ReactQueryOptions['pendingEvents']['updatePendingEvent']
export type UpdatePendingEventInput = RouterInputs['pendingEvents']['updatePendingEvent']
export type UpdatePendingEventOutput = RouterOutput['pendingEvents']['updatePendingEvent']

export function useUpdatePendingEvent(opts?: UpdatePendingEventOptions) {
  return trpc.pendingEvents.updatePendingEvent.useMutation(opts)
}

export function transformGetPendingEventOutputToUpdatePendingEventInput(
  data?: Extract<GetPendingEventRawOutput, {isNewSchema: true}>['pendingEvent'],
): UpdatePendingEventInput {
  const localTimezone = moment.tz.guess()

  if (!data) {
    const DUMMY_PENDING_EVENT: UpdatePendingEventInput = {
      _id: '',
      location: {
        coordinates: [0, 0],
        type: 'Point',
      },
      song: undefined,
      eventTitleFont: DEFAULT_EVENT_PAGE_TITLE_FONT,
      lightmode: false,
      accentColor: 'white',
      attendanceDisplayDisabled: true,
      guestlistEnabled: true,
      activityEnabled: true,
      displayOnThirdPartySites: true,
      displayEndTime: true,
      tickets: [
        {
          id: '0',
          name: 'Default Ticket',
          price: 10,
        },
      ],
      timezone: localTimezone,
    }
    return DUMMY_PENDING_EVENT
  }

  return {
    ...data,
    _id: data.eventId,
    location: {
      coordinates: [data.location.lng, data.location.lat],
      type: 'Point',
    },
    venue: {
      address: data.venueAddress,
      name: data.venueName,
      images: data.gallery,
    },
    youtubeLink: data.youtubeVideoId ? buildYoutubeVideoUrl(data.youtubeVideoId) : undefined,
    song: data.song
      ? {
          ...data.song,
          albumCover: data.song.albumCover ?? '',
        }
      : undefined,
    parentCategory: data.eventCategory.parentCategory,
    subCategory: data.eventCategory.subCategory,
    passwordEnabled: data.isPasswordProtected,
    timezone: data.timezone || localTimezone,
  }
}
