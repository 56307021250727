import React, {useState} from 'react'
import ReactTooltip from 'react-tooltip'

// pick a date util library
import MomentUtils from '@date-io/moment'
import {MuiThemeProvider} from '@material-ui/core'
import {createTheme} from '@material-ui/core/styles'
import {KeyboardDateTimePicker} from '@material-ui/pickers'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
/* eslint-disable @typescript-eslint/no-empty-interface */
import {MuiPickersOverrides} from '@material-ui/pickers/typings/overrides'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'
import moment from 'moment-timezone'

import PoshInfo from '../PoshInfo'

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof Required<MuiPickersOverrides>[P]
}
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends Required<overridesNameToClassKey> {}
}
export const customTheme = createTheme({
  shape: {
    borderRadius: 20,
  },
  palette: {
    primary: {
      main: '#181818',
      light: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#f00',
      paper: '#333333',
    },
    secondary: {
      main: '#fc0',
    },
    action: {
      active: '#7a7b7d',
      hover: '#7a7b7d',
      selected: '#7a7b7d',
      disabled: '#B9BCBD',
      disabledBackground: '#FFFFFF',
      focus: '#ffcc00',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      disabled: '#B9BCBD',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  overrides: {
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#A3A3A3',
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        backgroundColor: '#0000',
        borderRadius: '50px',
        border: '1px solid #7a7b7d',
      },
      input: {
        padding: '22px 14px',
        fontWeight: 'lighter',
        fontSize: '16px',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#FFFFFF',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#fc0',
        color: '#181818',
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        border: '14px solid #000000',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: '#fc0',
      },
    },
  },
})

interface PoshDatePickerProps {
  className?: string
  name?: string
  value?: string | undefined
  setDates: (localDate?: string, utcDate?: string) => void
  timezone: string
  noWrappingContainer?: boolean
  disabled?: boolean
  white?: boolean
  noHours?: boolean
  label?: string
  info?: string
  maxDate?: Date
  minDate?: Date
}

const PoshDatePicker = (props: PoshDatePickerProps) => {
  const {label, info, name, setDates, timezone, disabled, white, className, noHours, minDate, maxDate} = props
  const [value, setValue] = useState<string | undefined>(props.value)

  const format = noHours ? 'MM/DD/yyyy' : 'MM/DD/yyyy hh:mm a'
  const inputWrapperClassName = !!label ? 'inputLabelWrapper' : 'inputWrapper fullWidth'
  const Icon = () => {
    return (
      <PoshImage
        src={`https://images.posh.vip/b2/calendar-${white ? 'white' : 'grey'}.svg`}
        style={{width: '18px', height: '18px'}}
      />
    )
  }

  return (
    <div className={classNames(inputWrapperClassName, className)} data-tip data-for='timezone'>
      {label && (
        <div className={inputWrapperClassName + '-labelContatiner'}>
          <label className='noMargin'>{label}</label>
          {info && <PoshInfo info={info} />}
        </div>
      )}
      <div style={{width: '100%'}}>
        <MuiThemeProvider theme={customTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              inputVariant='outlined'
              InputProps={{
                disableUnderline: true,
              }}
              type='datetime'
              format={format}
              placeholder={name}
              value={value ? moment.tz(value, timezone) : null}
              keyboardIcon={<Icon />}
              onChange={e => {
                const dateUtc = e ? moment(e).utc().format() : undefined
                setValue(dateUtc) // This is the value that is displayed in the input. We keep it as UTC, but format it in the local timezone

                // THIS IS REALLY BROKEN BUT SOME CODE STILL RELIES ON IT
                // We represent the local date as a UTC timestamp and IN MANY PLACES (tickets, events) still store BOTH
                // For example, midnight in New York (-4) is stored BOTH as `date`: `00:00` AND `dateUtc`: `04:00`
                const tzAwareDate = moment.tz(e, timezone)
                const dateLocalButRepresentedAsUtc = e
                  ? tzAwareDate.clone().add(tzAwareDate.utcOffset(), 'minutes').utc().format()
                  : undefined
                setDates(dateLocalButRepresentedAsUtc, dateUtc)
              }}
              disabled={disabled ?? false}
              emptyLabel={disabled ? '' : name}
              minDate={minDate}
              maxDate={maxDate}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </div>
      <ReactTooltip id='timezone' effect='solid' place='right' disable={moment.tz.guess() === timezone}>
        <div>Timezone: {timezone}</div>
      </ReactTooltip>
    </div>
  )
}

export default PoshDatePicker
