import React, {PropsWithChildren} from 'react'

import {Checkbox, CheckboxProps} from 'components/form/checkbox/Checkbox'
import {useEventVisualsContext} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'

type EventVisualsCheckboxProps = Pick<CheckboxProps, 'onChecked' | 'checked' | 'label' | 'subtitle' | 'disabled'>

export const EventVisualsCheckbox = (props: PropsWithChildren<EventVisualsCheckboxProps>) => {
  const {
    palette: {accentColor, textContrasting},
  } = useEventVisualsContext()
  const checkboxProps = {
    hideBackground: true,
    color: accentColor,
    textColor: textContrasting,
    checkColor: textContrasting,
  }

  return (
    <Checkbox {...props} {...checkboxProps}>
      {props.children}
    </Checkbox>
  )
}
