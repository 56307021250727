import React from 'react'
import {Control, useController} from 'react-hook-form'
import ReactTooltip from 'react-tooltip'

import {getTimezoneInformation} from '@posh/model-types'
import {useGetTimezoneMutation} from 'apis/Util/useGetTimezone'
import {ClockWithCircle} from 'components/assets/Icons'
import {ToggleableEye} from 'components/assets/ToggleableEye'
import {EventVisualsCategorySelect} from 'components/PageComponents/EventVisuals/Form/Dropdown/EventVisualsCategoryDropdown'
import {EventVisualsDateTimeInput} from 'components/PageComponents/EventVisuals/Form/Input/DateInput'
import {EventVisualsMarkdownEditor} from 'components/PageComponents/EventVisuals/Form/Input/MarkdownInput'
import {EventVisualsTextInput} from 'components/PageComponents/EventVisuals/Form/Input/TextInput'
import {EventVisualsSection} from 'components/PageComponents/EventVisuals/Page/Section'
import {EventVisualsForm} from 'components/PageComponents/EventVisuals/types/eventVisualsForm'
import {EventTitleFontStyleProvider} from 'components/Text/EventTitleFontStyleProvider'
import {useToast} from 'components/toasts/ToastProvider'
import {useDimensions} from 'hooks/useDimensions'
import moment from 'moment-timezone'

import {useEventVisualsContext} from '../../context/EventVisualsContext'
import {EventVisualsCombinedVenueAddressInput} from './inputs/EventVisualsCombinedVenueAddressInput'

import styles from './styles.module.scss'

interface EventDetailsFormSectionProps {
  control: Control<EventVisualsForm>
  showShortDescription?: boolean
  showDescription?: boolean
}

const ICON_SIZE = 20

function Timezone(props: {timezone: string | undefined}) {
  const timezone = props.timezone || moment.tz.guess()
  const text = timezone.replace(/_/g, ' ').replace(/\//g, ', ')
  return (
    <p className='text-xs noMargin italic' style={{color: 'white'}}>
      Timezone: {text}
    </p>
  )
}

export function EventDetailsFormSection(props: EventDetailsFormSectionProps) {
  const {control, showShortDescription = true, showDescription = false} = props
  const {isMobile} = useDimensions()
  const {showToast} = useToast()
  const {
    palette: {accentColor, lightmode: lightMode, textContrasting},
    fontFamily,
  } = useEventVisualsContext()

  const {
    field: {onChange: setDisplayEndTime, value: displayEndTime},
  } = useController({
    control,
    name: 'displayEndTime',
  })

  const {
    field: {value: startUtc, onChange: onChangeStartUtc},
  } = useController({
    control,
    name: 'startUtc',
  })
  const {
    field: {value: endUtc, onChange: onChangeEndUtc},
  } = useController({
    control,
    name: 'endUtc',
  })
  const {
    field: {value: formTimezone, onChange: onChangeFormTimezone},
  } = useController({
    control,
    name: 'timezone',
  })
  const {
    field: {onChange: setDescriptionOnForm, value: description},
  } = useController({
    control,
    name: 'description',
  })

  const {mutate: getAndUpdateTimezoneFromLocation} = useGetTimezoneMutation({
    onSuccess: data => {
      const newTimezone = data?.timezone
      const previousTimezone = formTimezone
      if (newTimezone && previousTimezone && newTimezone !== previousTimezone) {
        onChangeFormTimezone(newTimezone)

        // to maintain the same local time, we need to update the start and end times

        if (startUtc) {
          const startLocal = moment(startUtc).tz(previousTimezone)
          const newStartLocal = startLocal.clone().tz(newTimezone, true)
          onChangeStartUtc(newStartLocal.utc().toISOString())
        }

        if (endUtc) {
          const endLocal = moment(endUtc).tz(previousTimezone)
          const newEndLocal = endLocal.clone().tz(newTimezone, true)
          onChangeEndUtc(newEndLocal.utc().toISOString())
        }

        const {friendlyName} = getTimezoneInformation(newTimezone)
        showToast({
          type: 'info',
          title: `Timezone Updated`,
          subtitle: `Since you changed the location of your event, we've updated the timezone to ${friendlyName}`,
        })
      }
    },
  })

  return (
    <>
      <EventVisualsSection headerText='Event Details'>
        <EventVisualsTextInput.Text.Controlled
          control={control}
          lightMode={lightMode}
          accentColor={accentColor}
          name='name'
          placeholder='My Event'
          autoComplete='none'
          rules={{required: 'Event name is required'}}
          size={isMobile ? 30 : 36}
          fontFamily={fontFamily}
        />
        <ReactTooltip id='timezone' effect='solid' place='right'>
          <Timezone timezone={formTimezone} />
        </ReactTooltip>
        <div className={styles.StartEndDatesContainer} data-tip data-for='timezone'>
          <EventVisualsDateTimeInput.DateTime.Controlled
            control={control}
            name='startUtc'
            placeholder='Start time'
            rules={{
              required: 'Start time is required',
              validate: (value: any) => {
                const momentEndUtc = moment(endUtc)
                const momentStartUtc = moment(value)
                return endUtc && value && momentStartUtc.isBefore(momentEndUtc)
              },
            }}
            accentColor={accentColor}
            lightMode={lightMode}
            leftIcon={<ClockWithCircle height={ICON_SIZE} width={ICON_SIZE} color={accentColor} />}
            timezone={formTimezone}
            displayTimezone
          />
          <EventVisualsDateTimeInput.DateTime.Controlled
            control={control}
            name='endUtc'
            accentColor={accentColor}
            placeholder='End time'
            rules={{
              required: 'End time is required',
              validate: (value: any) => {
                const momentStartUtc = moment(startUtc)
                const momentEndUtc = moment(value)
                return startUtc && value && momentStartUtc.isBefore(momentEndUtc)
              },
            }}
            lightMode={lightMode}
            rightIcon={
              <ToggleableEye
                size={ICON_SIZE}
                onClick={() => setDisplayEndTime(!displayEndTime)}
                visible={displayEndTime || false}
                color={textContrasting}
              />
            }
            timezone={formTimezone}
            displayTimezone
          />
        </div>
        {startUtc && endUtc && moment(startUtc).isAfter(moment(endUtc)) && (
          <p className='error text-small noMargin'>Start time must be before end time</p>
        )}
        <EventVisualsCombinedVenueAddressInput
          control={control}
          onChangeAddress={placeResult => {
            const lat = placeResult.geometry?.location?.lat()
            const lon = placeResult.geometry?.location?.lng()
            if (lat && lon) getAndUpdateTimezoneFromLocation({lat, lon})
          }}
        />
        <EventVisualsCategorySelect.Controlled
          control={control}
          parentCategoryFormName='parentCategory'
          subCategoryFormName='subCategory'
          accentColor={accentColor}
          lightMode={lightMode}
        />
        {showShortDescription && (
          <EventVisualsTextInput.Text.Controlled
            control={control}
            lightMode={lightMode}
            accentColor={accentColor}
            name='shortDescription'
            placeholder='Short Event Summary (optional)'
          />
        )}
        {showDescription && (
          <EventVisualsMarkdownEditor
            placeholder={'Show your attendees what they can expect from your event.'}
            value={description ?? ''}
            onChange={setDescriptionOnForm}
          />
        )}
      </EventVisualsSection>
      <EventTitleFontStyleProvider eventTitleFont={fontFamily} />
    </>
  )
}
