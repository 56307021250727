import React from 'react'

import {TLocation} from '@posh/model-types'
import {SORT_OPTIONS, SortOption, WHEN_OPTIONS, WhenOption, WHERE_OPTIONS} from '@posh/types'
import {
  UnderlinedDropdown,
  UnderlinedDropdownOption,
  UnderlinedDropdownOptionType,
} from 'components/Dropdown/UnderlinedDropdown'
import {useSelectLocation} from 'hooks/useSelectLocation'

import './styles.scss'

export interface EventMarketplaceMainFilters {
  sort: SortOption
  when: WhenOption
}
export const MainFilters = ({
  sort,
  when,
  onUpdateFilter,
  location,
  onUpdateLocation,
}: EventMarketplaceMainFilters & {
  onUpdateFilter: (filter: keyof EventMarketplaceMainFilters, value: SortOption | WhenOption) => void
  location: TLocation
  onUpdateLocation: (location: TLocation) => void
}) => {
  const onSelectSort = (value: SortOption) => {
    onUpdateFilter('sort', value)
  }

  const onSelectWhen = (value: WhenOption) => {
    onUpdateFilter('when', value)
  }

  const {isLoadingNearMeLocation, onSelectLocation} = useSelectLocation({
    onLocationSelected: location => {
      onUpdateLocation(location)
    },
  })

  const sortOptions = SORT_OPTIONS.filter(o => o !== sort).map(o => ({
    type: 'text' as UnderlinedDropdownOptionType,
    value: o,
  }))
  const whenOptions = WHEN_OPTIONS.filter(o => o !== when).map(o => ({
    type: 'text' as UnderlinedDropdownOptionType,
    value: o,
  }))
  const whereOptions: UnderlinedDropdownOption[] = WHERE_OPTIONS.filter(o => o !== location.location).map(o => ({
    type: 'text' as UnderlinedDropdownOptionType,
    value: o,
  }))
  whereOptions.push({type: 'city-input'})

  return (
    <div className='MainFilters' onClick={e => e.stopPropagation()}>
      <UnderlinedDropdown
        selectedValue={sort}
        options={sortOptions}
        onSelect={value => onSelectSort(value as SortOption)}
      />
      <UnderlinedDropdown
        selectedValue={when}
        options={whenOptions}
        onSelect={value => onSelectWhen(value as WhenOption)}
      />
      <div className='MainFilters-whereContainer'>
        <p>in</p>
        <UnderlinedDropdown
          selectedValue={isLoadingNearMeLocation ? 'Loading Location...' : location.location}
          options={whereOptions}
          onSelect={onSelectLocation}
        />
      </div>
    </div>
  )
}
