import {useQuery} from '@tanstack/react-query'
import PoshApi from 'apis/PoshApi'

// Settings
export async function getRsvpSettings(eventId: string) {
  const response = await PoshApi.getRsvpSettings(eventId)
  return response.data
}

export interface GetRSVPSettingsResponse {
  requireApproval: boolean
  displaySpotsAvailablePublicly: boolean
  closeRsvps: boolean
  enableReceipts: boolean
  totalSpotsClaimed: number
  totalSpots: number
  isTransferable: boolean
}

export function useFetchEventRsvpSettings(eventId: string) {
  return useQuery<GetRSVPSettingsResponse>(['eventRsvpSettings', eventId], () => getRsvpSettings(eventId), {
    enabled: !!eventId,
  })
}
