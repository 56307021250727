import {captureException} from '@sentry/react'

interface DataLayerEvent {
  event: string
  [key: string]: any
}

/**
 * Pushes an event to the Google Tag Manager dataLayer.
 * @param data An object representing the event to be pushed to the dataLayer.
 */
export const pushToDataLayer = (data: DataLayerEvent) => {
  try {
    if (window?.dataLayer) {
      window.dataLayer.push(data)
    }
  } catch (error) {
    captureException(error)
  }
}
