// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StartEndDatesContainer__kGaVY{display:grid;gap:10px;grid-template-columns:1fr 1fr}", "",{"version":3,"sources":["webpack://./src/pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventDetails/styles.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,QAAA,CACA,6BAAA","sourcesContent":[".StartEndDatesContainer {\n  display: grid;\n  gap: 10px;\n  grid-template-columns: 1fr 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StartEndDatesContainer": "StartEndDatesContainer__kGaVY"
};
export default ___CSS_LOADER_EXPORT___;
