import React, {useRef, useState} from 'react'

import {useLoginWithPhone} from 'apis/Auth/useLoginWithPhone'
import {useMixpanel} from 'apis/MixPanelHandler'
import CloudflareTurnstile, {TurnstileRef} from 'components/CloudflareTurnstile'
import useSessionContext from 'domains/Auth/SessionContext'
import useLiveCartContext, {tokenCheckoutFormSchema} from 'domains/LiveCart/LiveCartContext'
import {isUndefined} from 'lodash'
import {CartError} from 'pages/NewCheckoutPage/CartError'

import {PhoneLoginFormFirstStep} from './firstStep'
import {PhoneLoginFormSecondStep} from './secondStep'

interface PhoneLoginFormProps {
  hasAccount: boolean
  setHasAccount: React.Dispatch<React.SetStateAction<boolean>>
  phone: string
  setPhone: React.Dispatch<React.SetStateAction<string>>
  setSignupToken: React.Dispatch<React.SetStateAction<string | undefined>>
  setShowSignUp: React.Dispatch<React.SetStateAction<boolean>>
  showSignUp: boolean
}

export const PhoneLoginForm = ({
  hasAccount,
  setHasAccount,
  setPhone,
  phone,
  setSignupToken,
  setShowSignUp,
  showSignUp,
}: PhoneLoginFormProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {cartFormData, setFormErrors, isRSVP, validateAllRequiredCheckoutFields, formErrors} = useLiveCartContext()
  const {userId} = useSessionContext()
  const {mutateAsync: sendLoginCode, data: loginWithPhoneRes, isLoading: isSendingCode} = useLoginWithPhone()
  const [hasExistingAccount, setHasExistingAccount] = useState<boolean | undefined>(undefined)
  const [needsToConfirmAccount, setNeedsToConfirmAccount] = useState<boolean | undefined>(undefined)

  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const captchaRef = useRef<TurnstileRef>(null)

  const handlePhoneCheckClick = async () => {
    trackMixpanelEvent('Phone Auth Clicked-  Phone Auth Form')
    try {
      const phone = cartFormData['phone_number']?.value ?? ''
      const validphone = tokenCheckoutFormSchema.validateSyncAt(
        'phone_number',
        {phone_number: phone},
        {abortEarly: false},
      )
      if (validphone) {
        setFormErrors({})
        if (isRSVP && !validateAllRequiredCheckoutFields()) return
        if (!captchaToken) {
          return
        }
        if (captchaRef.current?.isExpired()) {
          resetCaptcha()
          return
        }
        const {
          success,
          hasAccount: hasExistingAccount,
          needsToConfirmAccount,
        } = await sendLoginCode({phone, cfTurnstileResponse: captchaToken})
        if (success) {
          setHasAccount(true)
          setHasExistingAccount(hasExistingAccount)
          setNeedsToConfirmAccount(needsToConfirmAccount)
          setPhone(phone)
          trackMixpanelEvent('Phone Auth Success-  Phone Auth Form')
        } else {
          setFormErrors({phone_number: 'Invalid phone number'})
          resetCaptcha()
        }
      }
    } catch (error: any) {
      if (error.response) {
        trackMixpanelEvent('Phone Auth Fail-  Phone Auth Form', {error: error.response})
        setFormErrors({phone_number: error.response.data.message})
      } else {
        const validationError = error.inner[0]
        const {path, message} = validationError
        trackMixpanelEvent('Phone Auth Fail-  Phone Auth Form', {error: message})
        setFormErrors({[path]: message})
      }
      resetCaptcha()
    }
  }

  const resetCaptcha = () => {
    // Reset the captcha token in our current state since it's no longer valid
    setCaptchaToken(undefined)
    // Tell the captcha widget to reset its internal state and start over a new captcha challenge
    // This doesn't trigger the reset callback, which is why we have to manually clear the state above
    captchaRef.current?.reset()
  }

  const handleCaptchaToken = (token: string) => {
    setCaptchaToken(token)
  }

  return (
    <>
      <>
        {loginWithPhoneRes?.success === true && !userId && hasAccount && !!phone && !isUndefined(hasExistingAccount) ? (
          <PhoneLoginFormSecondStep
            phone={phone}
            hasExistingAccount={hasExistingAccount}
            needsToConfirmAccount={needsToConfirmAccount!}
            setSignupToken={setSignupToken}
            setHasAccount={setHasAccount}
            setShowSignUp={setShowSignUp}
          />
        ) : (
          <PhoneLoginFormFirstStep
            phone={phone}
            setPhone={setPhone}
            handlePhoneCheckClick={handlePhoneCheckClick}
            isSendingCode={isSendingCode}
            showSignUp={showSignUp}
            captchaToken={captchaToken}
            resetCaptcha={resetCaptcha}
          />
        )}
      </>
      <CloudflareTurnstile
        ref={captchaRef}
        successCallback={handleCaptchaToken}
        resetCaptchaCallback={() => setCaptchaToken(undefined)}
      />
      <CartError error={formErrors.phone_number} />
    </>
  )
}
