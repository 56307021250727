import React from 'react'

import {FileOrderResponse, isValidUrl, OrderResponse, prependHttpsProtocolIfMissing} from '@posh/model-types'
import classNames from 'classnames'

import CheckoutFieldResponse from './CheckoutFieldResponse'
import getResponseValue from './getResponseValue.helper'

const CheckoutFieldTextResponse = (response: Exclude<OrderResponse, FileOrderResponse>) => {
  const value = getResponseValue(response)
  const responseIsUrl = isValidUrl(value)
  const onClickResponse = () => {
    if (responseIsUrl) {
      window.open(prependHttpsProtocolIfMissing(value), '_blank')
    } else return
  }

  return (
    <CheckoutFieldResponse>
      <CheckoutFieldResponse.Label>{response.prompt}</CheckoutFieldResponse.Label>
      <CheckoutFieldResponse.Value onClick={onClickResponse} className={classNames({['primary-link']: responseIsUrl})}>
        {getResponseValue(response)}
      </CheckoutFieldResponse.Value>
    </CheckoutFieldResponse>
  )
}

export default CheckoutFieldTextResponse
