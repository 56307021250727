import {ReactQueryOptions, RouterInputs, RouterOutput, trpc} from 'lib/trpc'

type GetTimezoneOptions = ReactQueryOptions['utils']['getTimezone']
type GetTimezoneInput = RouterInputs['utils']['getTimezone']
export type GetTimezoneOutput = RouterOutput['utils']['getTimezone']

export function useGetTimezone(input: GetTimezoneInput, opts?: GetTimezoneOptions) {
  return trpc.utils.getTimezone.useQuery(input, opts)
}

type GetTimezoneMutationOptions = ReactQueryOptions['utils']['getTimezoneMutation']

export function useGetTimezoneMutation(opts?: GetTimezoneMutationOptions) {
  return trpc.utils.getTimezoneMutation.useMutation(opts)
}
