import {captureException} from '@sentry/react'
import {FetchEventResponsePublic} from 'apis/Events/useFetchEvent'

import {pushToDataLayer} from '../dataLayerUtils'

export const eventPageView = (event: FetchEventResponsePublic['event']) => {
  try {
    // Push the event data to the dataLayer event
    pushToDataLayer({
      event: 'eventView', // Mandatory event name
      eventId: event.id,
      eventName: event.name,
      fullEvent: event,
    })
  } catch (error) {
    captureException(error)
  }
}
