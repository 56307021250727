import React, {useState} from 'react'

import {useFetchEventTickets} from 'apis/Events/useFetchEventTickets'
import useSendFreeTickets from 'apis/Orders/useSendFreeTickets'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import {useResourcePageParams} from 'pages/PoshAppLayout'

interface ComplimentaryTicketSelectorProps {
  userBeingAdded: string
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  setUserBeingAdded: React.Dispatch<React.SetStateAction<string>>
  onSendTickets?: (quantity: number) => void
}

export default function ComplimentaryTicketSelector(props: ComplimentaryTicketSelectorProps) {
  const {userBeingAdded, setSuccessMessage, setErrorMessage, setUserBeingAdded, onSendTickets} = props
  const [ticketType, setTicketType] = useState('')
  const [quantity, setQuantity] = useState(1)
  const {eventId} = useResourcePageParams()
  const {data: tickets} = useFetchEventTickets(eventId!)
  const {mutateAsync: sendFreeTickets, isLoading} = useSendFreeTickets()

  const handleSubmit = async () => {
    setSuccessMessage('')
    setErrorMessage('')
    if (!ticketType || quantity === 0 || !eventId) {
      setErrorMessage('Please select a ticket type and quantity')
      return
    }
    onSendTickets?.(quantity)
    try {
      const message = await sendFreeTickets({accountId: userBeingAdded, ticketType, quantity, eventId})
      setUserBeingAdded('')
      setSuccessMessage(message)
      return
    } catch (error) {
      setErrorMessage(error.response.data.error)
      return
    }
  }

  return (
    <div className='AccountSearchModal'>
      <h3>Send Complimentary Tickets</h3>
      <div className='AccountSearchModal-inputWrapper'>
        <div style={{width: '100%'}}>
          <Select onChange={e => setTicketType(e.target.value)}>
            <option value=''>Ticket Type</option>
            {tickets?.map(ticket => (
              <option key={ticket.ticket.id} value={ticket.ticket.id}>
                {ticket.ticket.name}
              </option>
            ))}
          </Select>
        </div>
        <Input
          value={quantity}
          type={'number'}
          placeholder={'Quantity'}
          onChange={e => setQuantity(e.target.valueAsNumber)}
        />
      </div>
      <Button disabled={isLoading} className='fullWidth' onClick={handleSubmit}>
        {quantity > 1 ? 'Send Tickets' : 'Send Ticket'}
      </Button>
    </div>
  )
}
