import React, {useEffect, useState} from 'react'

import {AccountInviteEntryPoint} from '@posh/models'
import {isUndefined} from 'lodash'
import {LoginFlow} from 'pages/LoginPage'

import PoshStyledModal, {PoshStyledModalProps} from '../PoshStyledModal'
import AccountSearchModalPrimaryStep from './AccountSearchModalPrimaryStep'
import AccountSearchModalSecondaryStep from './AccountSearchModalSecondaryStep'

import './styles.scss'

interface AccountSearchModalProps extends PoshStyledModalProps {
  onAddPoshUser: (account: AccountParams) => Promise<void>
  onAddNonPoshUser: (account: AccountParams) => Promise<void>
  userBeingAdded: string
  successMessage: string
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  primaryTitle: string
  primaryButtonText: string
  secondaryTitle: string
  secondaryButtonText: string
  ThirdComponent?: JSX.Element
  onClickPrimaryButton?: () => void
  onSecondaryButtonClick?: () => void
  invitedFrom: AccountInviteEntryPoint
}

export interface SearchedData {
  data: string
  type: LoginFlow
}

export interface AccountParams {
  accountId: string
  email?: string
  phone?: string
}

const AccountSearchModal = (props: AccountSearchModalProps) => {
  const [step, setStep] = useState<1 | 2 | 3>(1)
  const [searchedEmail, setSearchedEmail] = useState('')
  const [searchedPhone, setSearchedPhone] = useState('')
  const {
    onAddPoshUser,
    onAddNonPoshUser,
    successMessage,
    errorMessage,
    setErrorMessage,
    primaryTitle,
    primaryButtonText,
    userBeingAdded,
    secondaryTitle,
    secondaryButtonText,
    ThirdComponent,
    onClickPrimaryButton,
    onSecondaryButtonClick,
    invitedFrom,
  } = props

  const shouldShowThirdComponent = step === 3 && !isUndefined(ThirdComponent) && userBeingAdded !== ''

  const resetModal = () => {
    setStep(1)
    setSearchedEmail('')
    setSearchedPhone('')
    setErrorMessage('')
  }

  const addUserWrapper = (addAccount: (account: AccountParams) => Promise<void>) => {
    return async (account: AccountParams) => {
      if (!isUndefined(ThirdComponent)) {
        await addAccount(account)
        setStep(3)
      } else await addAccount(account)
    }
  }

  useEffect(() => {
    if (userBeingAdded === '' && !isUndefined(ThirdComponent) && step === 3) resetModal()
  }, [userBeingAdded])

  const switchToSecondaryStep = (searchedData: SearchedData) => {
    onClickPrimaryButton && onClickPrimaryButton()
    if (searchedData.type === 'email') setSearchedEmail(searchedData.data)
    else setSearchedPhone(searchedData.data)
    setStep(2)
  }

  return (
    <PoshStyledModal
      noborder={props.noborder}
      isOpen={props.isOpen}
      width={props.width}
      onClose={() => {
        resetModal()
        props.onClose()
      }}>
      <>
        {step === 1 ? (
          <AccountSearchModalPrimaryStep
            onAddUser={addUserWrapper(onAddPoshUser)}
            userBeingAdded={userBeingAdded}
            primaryTitle={primaryTitle}
            primaryButtonText={primaryButtonText}
            switchToSecondaryStep={switchToSecondaryStep}
          />
        ) : step === 2 ? (
          <AccountSearchModalSecondaryStep
            searchedEmail={searchedEmail}
            searchedPhone={searchedPhone}
            secondaryTitle={secondaryTitle}
            invitedFrom={invitedFrom}
            secondaryButtonText={secondaryButtonText}
            onSecondaryButtonClick={onSecondaryButtonClick}
            onAddUser={addUserWrapper(onAddNonPoshUser)}
            setErrorMessage={setErrorMessage}
            switchToPrimaryStep={() => (!isUndefined(ThirdComponent) ? setStep(3) : setStep(1))}
            isLoading={userBeingAdded !== ''}
          />
        ) : (
          shouldShowThirdComponent && ThirdComponent
        )}
      </>
      {successMessage && <p className='AccountSearchModal success text-small'>{successMessage}</p>}
      {errorMessage && <p className='AccountSearchModal error text-small'>{errorMessage}</p>}
    </PoshStyledModal>
  )
}

export default AccountSearchModal
