import React from 'react'

import {Modal} from 'components/coreComponents/Modal'
import {SpinLoader} from 'components/Loaders/SpinLoader'

import {EnablePayments, EnablePaymentsProps} from './bodies/EnablePayouts'
import {LaunchBody, LaunchBodyProps} from './bodies/Launch'

import styles from './styles.module.scss'

export type CreateEventLaunchModalProps = {
  isOpen: boolean
  onClose: () => void
  needsPaymentsEnabled: boolean
  isLoadingNeedsPaymentsEnabled: boolean
  groupId: string
} & Omit<LaunchBodyProps, 'onConfirm'> &
  Omit<EnablePaymentsProps, 'onConfirm'> & {
    onConfirm: (groupId: string, status: 'live' | 'draft', shouldNavigateToFinanceSettings: boolean) => void
  }

export function CreateEventLaunchModal(props: CreateEventLaunchModalProps) {
  const {needsPaymentsEnabled, isLoadingNeedsPaymentsEnabled} = props

  const onConfirm = (status: 'live' | 'draft', shouldNavigateToFinanceSettings: boolean) => {
    props.onConfirm(props.groupId, status, shouldNavigateToFinanceSettings)
  }

  return (
    <Modal.Glass {...props} className={styles.glassView} blurRadius={25} blurTint='dark' brightness={0.9}>
      {isLoadingNeedsPaymentsEnabled ? (
        <SpinLoader />
      ) : needsPaymentsEnabled ? (
        <EnablePayments {...props} onConfirm={onConfirm} />
      ) : (
        <LaunchBody {...props} onConfirm={onConfirm} />
      )}
    </Modal.Glass>
  )
}
